
export const Path = {
    Dashboard: "/dashboard",
    TelemetryRoot: "/telemetry",
    Weather:"/master/weathers",
    WeatherData: "/master/weatherdata",
    UsersList: "/master/users",
    CustomersList: "/master/customers",
    CustomersGroupList: "/master/customers-group",
    BaseList: "/master/base",
    AlarmList: "/telemetry/alarm",
    AbacList: "/telemetry/abac",
    AdList: "/telemetry/ad",
    GbList: "/telemetry/gb",
    GcList: "/telemetry/gc",
    EbecList: "/telemetry/ebec",
    BxList: "/telemetry/bx",
    BeList: "/telemetry/be",
    GaList: "/telemetry/ga",
    Ga2List: "/telemetry/ga2",
    AeList: "/telemetry/ae",
    DemandList: "/telemetry/demand",
    Gaex1: "/telemetry/gaex1",
    Gaex2: "/telemetry/gaex2",
    Gaex3: "/telemetry/gaex3",
    Gaex5: "/telemetry/gaex5",
    MonthlyPowerUpdate: "/telemetry/monthlyPowerUpdate",
    DeviceList: "/polling/device-list",
    DeviceJSPList: "/polling/deviceJSP-list",
    PollingTopicDetail: "/polling/detail",
    AddDevice: "/polling/device/add",
    AddDeviceList: "/polling/device/addList",
    EditDevice: "/polling/device/edit",
    PointsEdit: "/polling/points",
    LeaksEdit: "/polling/leaks",
    DemandsEdit: "/polling/demands",
    SolarsEdit: "/polling/solar",
    NotificationsEdit: "/polling/notifications",
    ReportDemand: "/polling/reportDemand",
    SmartDemandList: "/smonitor/smartDemandList",
    AlarmDemandList: "/smonitor/alarmDemandList",
    SettingDemandList: "/smonitor/settingDemandList",
    SmartSolarList: "/smonitor/smartSolarList",
    SettingSolarList: "/smonitor/settingSolarList",
    SolarLiveStatus: "/solarLiveStatus",
    Edit212: "/212-edit",
    CheckAPIs: "/checkapi",
    FaList:"/telemetry/fa",
    NotifyList:"/polling/notify-list",
    ObservationPointsAndDeviceScreen:"/master/observation_points_and_device_screen",
}
