import { Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";

import { useForm } from "react-hook-form";
import { useCSVDownloader } from 'react-papaparse';
import { useNavigate } from "react-router";
import { DeviceModel, OptionItem } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/AppStore";

import { Close } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { useCSVReader } from 'react-papaparse';
import FetchHelper from "../../FetchHelper";
import { LoadingButton } from "@mui/lab";
import AlertDialog from "../../common/AlertDialog";
import { limitMasterCustomers, customerLabelName } from "../../../statics";

// -------------
// FETCH ID
const PAGE = "DEVICE_LIST";
const getAllCustomers = `GET_ALL_CUSTOMERS_${PAGE}`;
const createGateways = "CREATE_GATEWAYS";
const updatePoint = "UPDATE_POINT";
const updateLeaks = "UPDATE_LEAKS";
const updateDemand = "UPDATE_DEMAND";
const updateSolars= "UPDATE_SOLARS";
const updateNotifications= "UPDATE_NOTIFICATIONS";
const UPDATE_LIST_ADDRESS ="UPDATE_LIST_ADDRESS";

export type AddDeviceListFormProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// フォーム定義
interface AddDeviceListForm {
    id: string,
    ipAddress: string,
    customer: OptionItem | null,
    place: string,
    pulseType: string,
}

// 初期値設定
const initialValue: AddDeviceListForm = {
    id: "",
    ipAddress: "",
    customer: null,
    place: "",
    pulseType: "0",
}

export const AddDeviceList = (props: AddDeviceListFormProps) => {
    localStorage.setItem('title', '監視装置');
    let navigate = useNavigate();

    const { onCreateError, onGetAllCustomer, onCreateGateways, onUpdatePoints, onUpdateLeaks, onUpdateDemands, onUpdateSolars, onUpdateNotifications, onUpdateListAddress } = props;
    const [runFlag, setRunFlag] = useState(false);
    const { CSVDownloader, Type } = useCSVDownloader();
    const { CSVReader } = useCSVReader();
    
    const { register, handleSubmit, formState: { errors }, control } = useForm<DeviceModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    const headerCSV = `${customerLabelName}名,装置ID,設置場所,IPアドレス,パルス種別,接点-接点名称1,接点-警報判定値1,接点-復帰メッセージ1,接点-警報メッセージ1,接点-接点名称2,接点-警報判定値2,接点-復帰メッセージ2,接点-警報メッセージ2,漏電-接点名称1,漏電-接点名称2,漏電-接点名称3,漏電-接点名称4,漏電-接点名称5,漏電-接点名称6,デマンド-閾値1,デマンド-閾値2,デマンド-閾値3,太陽光-名称,太陽光-単価,太陽光-予想電力-1月,太陽光-予想電力-2月,太陽光-予想電力-3月,太陽光-予想電力-4月,太陽光-予想電力-5月,太陽光-予想電力-6月,太陽光-予想電力-7月,太陽光-予想電力-8月,太陽光-予想電力-9月,太陽光-予想電力-10月,太陽光-予想電力-11月,太陽光-予想電力-12月,太陽光-パネル容量,通報-停電-メールアドレス,通報-停電-Slack通知,通報-復電-メールアドレス,通報-復電-Slack通知,通報-接点警報-メールアドレス,通報-接点警報-Slack通知,通報-デマンド注意警報-メールアドレス,通報-デマンド注意警報-Slack通知,通報-デマンド限界警報-メールアドレス,通報-デマンド限界警報-Slack通知,通報-軽漏電警報-メールアドレス,通報-軽漏電警報-Slack通知,通報-重漏電警報-メールアドレス,通報-重漏電警報-Slack通知,帳票配信-配信先メールアドレス,管理組織名`
    const [dataCSV, setDataCSV] = useState<any>([]);
    const [saveDataCSV, setSaveDataCSV] = useState<any[]>([]);
    const [customerDB, setCustomerDB] = useState<any[]>([]);
    const [errorList, setErrorList] = useState('');
    const [selectCSV, setSelectCSV] = useState(false);
    const [fileName, setFileName] = useState('');
    const [filePath, setFilePath] = useState('');
    const [openBackDialog, setOpenBackDialog] = useState(false);
    const [indexCSV, setIndexCSV] = useState(0);
    const [indexData, setIndexData] = useState(0);

    const [checkError, setCheckError] = useState(false);
    const [validateFlag, setValidateFlag] = useState(false);
    const [apiFlag, setApiFlag] = useState(false);

    const [updateFlag, setUpdateFlag] = useState(false);
    const [clickCancelFlag, setClickCancelFlag] = useState(false);


    const message1 = "列数が不正です。($1行目、列数：$2)\n";
    const message2_4_6 = "フォーマットが不正です。($1行目、項目：$2)\n";
    const message3 = "必須項目が設定されていません。($1行目、項目：$2)\n";
    // const message4 = "フォーマットが不正です。($1行目、項目：$2)\n";
    const message5 = `${customerLabelName}が登録されていません。($1行目、項目：$2)\n`;
    // const message6 = "フォーマットが不正です。($1行目、項目：$2)\n";

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        setSaveDataCSV([])
        setClickCancelFlag(false)
        setCheckError(false)
        setUpdateFlag(false)
        setRunFlag(true)
        setValidateFlag(false)
        setApiFlag(false)
        setIndexCSV(0)
        setIndexData(0)
        const params = new Map<string, any>();
        params.set("limit", limitMasterCustomers);
        let totalRow = dataCSV.length === 0 ? 0 : dataCSV.length - 1
        let error = fileName + "一括登録を開始します。(合計：" + totalRow + "件)\n"
        if(totalRow> 0){
            error = error + "ファイルのフォーマットチェックを開始します。\n"
            onGetAllCustomer(params)
        }else{
            error += "一括登録が完了しました。(合計：$1件)\n".replace("$1", "0")
            setRunFlag(false)
        }
        setErrorList(error)
    }

    // キャンセル
    const handleCancel = () => {
        if(!runFlag){
            navigate(RoutePath.DeviceList);
        }else{
            setClickCancelFlag(false)
            setOpenBackDialog(true)
        }
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        onCreateError();
        setRunFlag(false)
    };

    const lowerCaseString = (string:any) => {
        if(string != undefined && string != null){
            if(string.length > 0){
                return string.toString().toLowerCase();
            }
        }
        return string;
    }

    useEffect(() => {
        if(indexCSV > 0 && runFlag && !clickCancelFlag){
            let customerNameList = customerDB.map((x: any) => x.name)
            let error = '';
            let rowData = Object.values(dataCSV[indexCSV]).map((val: any) => val.trim());
            let isEmptyRow = rowData.find((item: any) => item.trim().length > 0);
            if(isEmptyRow){
                error += checkValidation(rowData, indexCSV, customerNameList)
            }

            if(error !== ''){
                setCheckError(true)
            }

            setSaveDataCSV(oldArray => [...oldArray,rowData] );

            let totalRow = dataCSV.length === 0 ? 0 : dataCSV.length - 1
            if(totalRow === indexCSV){
                setApiFlag(true);
                setValidateFlag(false)
                setApiFlag(true)
                setIndexData(1);

                if(checkError || error !== ''){
                    setErrorList(errorList + error + "ファイルのフォーマットチェックが完了しました。\n");
                }else{
                    setErrorList(errorList + "ファイルのフォーマットチェックが完了しました。\n登録処理を開始します。\n");
                }
            }else{
                setErrorList(errorList + error);
                if (!openBackDialog){
                    setIndexCSV(indexCSV + 1)
                }
            }
            
        }
        // eslint-disable-next-line
    }, [indexCSV]);

    useEffect(() => {
        if(indexData > 0 && runFlag && !clickCancelFlag && !checkError){
            if(saveDataCSV.length > 0){
                let request = {
                    id: saveDataCSV[0][1],
                    ip_address: saveDataCSV[0][3],
                    customer_id: customerDB.filter((r: any) => r.name === saveDataCSV[0][0])[0].id,
                    place: saveDataCSV[0][2],
                    pulse_type: saveDataCSV[0][4],
                    emg_alarm_leak: "1",
                    manage_customer_id: saveDataCSV[0][52] ? customerDB.filter((r: any) => r.name === saveDataCSV[0][52])[0].id : "",
                } as any;
                setUpdateFlag(true)
                onCreateGateways(request);
            }        
        }

        if(checkError && runFlag){
            setRunFlag(false);
        }
        // eslint-disable-next-line
    }, [indexData]);

    useEffect(() => {
        if(clickCancelFlag){
            setErrorList(errorList + "一括登録を中断しました。")    
            setRunFlag(false);
            setClickCancelFlag(false)
        }
        // eslint-disable-next-line
    }, [updateFlag]);

    // 検索正常終了時
    const handleSuccessGetAllCustomers = (customerData: any) => {
        console.log('>>> handleSuccessGetAllCustomers');
        if (customerData && customerData.results) {
            setCustomerDB(customerData.results)

            let customerNameList = customerData.results.map((x: any) => x.name)
            let error = '';
            let rowData = Object.values(dataCSV[0]).map((val: any) => val.trim());
            let isEmptyRow = rowData.find((item: any) => item.trim().length > 0);
            if(isEmptyRow){
                error += checkValidation(rowData, 0, customerNameList)
            }
            if(error !== ''){
                setCheckError(true)
                setErrorList(errorList + error);
            }

            setIndexCSV(1)
            setValidateFlag(true)
        }
    };

    const handleCreateGateways = (data: any) => {
        console.log('>>> handleCreateGateways');
        if(data.result_code === 0){
            let request = {
                mainid: saveDataCSV[0][1],
                point_info_array: [
                    {
                        ch: '1',
                        name: saveDataCSV[0][5],
                        alarm_on: saveDataCSV[0][6],
                        alarm_message: saveDataCSV[0][8],
                        restore_message: saveDataCSV[0][7],
                    },
                    {
                        ch: '2',
                        name: saveDataCSV[0][9],
                        alarm_on: saveDataCSV[0][10],
                        alarm_message: saveDataCSV[0][12],
                        restore_message: saveDataCSV[0][11],
                    }
                ]
            } as any;
            onUpdatePoints(request)
        }else{
            setErrorList(errorList + 
                "登録処理に失敗しました。($1行目、装置ID：$2)\n".replace("$1", indexData.toString()).replace("$2", saveDataCSV[0][1]) +
                "一括登録を中断しました。\n"
            );
            setRunFlag(false)
        }
    };

    const handleUpdatePoints = (data: any) => {
        console.log('>>> handleUpdatePoints');
        if(data.result_code === 0){
            let request = {
                mainid: saveDataCSV[0][1],
                leak_info_array: [
                    {
                        ch: '1',
                        name: saveDataCSV[0][13],
                    },
                    {
                        ch: '2',
                        name: saveDataCSV[0][14],
                    },
                    {
                        ch: '3',
                        name: saveDataCSV[0][15],
                    },
                    {
                        ch: '4',
                        name: saveDataCSV[0][16],
                    },
                    {
                        ch: '5',
                        name: saveDataCSV[0][17],
                    },
                    {
                        ch: '6',
                        name: saveDataCSV[0][18],
                    }
                ]
            } as any;
            onUpdateLeaks(request)
        }else{
            setErrorList(errorList + 
                "登録処理に失敗しました。($1行目、装置ID：$2)\n".replace("$1", indexData.toString()).replace("$2", saveDataCSV[0][1]) +
                "一括登録を中断しました。\n"
            );
            setRunFlag(false)
        }
    };

    const handleUpdateLeaks = (data: any) => {
        console.log('>>> handleUpdateLeaks');
        if(data.result_code === 0){
            let request = {
                mainid: saveDataCSV[0][1],
                th1st_kw: saveDataCSV[0][19],
                th2nd_kw: saveDataCSV[0][20],
                th3rd_kw: saveDataCSV[0][21],
                th1st_visible: "1",
                th2nd_visible: "1",
                th3rd_visible: "1",
            } as any;
            onUpdateDemands(request)
        }else{
            setErrorList(errorList + 
                "登録処理に失敗しました。($1行目、装置ID：$2)\n".replace("$1", indexData.toString()).replace("$2", saveDataCSV[0][1]) +
                "一括登録を中断しました。\n"
            );
            setRunFlag(false)
        }
    };

    const handleUpdateDemands = (data: any) => {
        console.log('>>> handleUpdateDemands');
        if(data.result_code === 0){
            let request = {
                mainid: saveDataCSV[0][1],
                plant_name: saveDataCSV[0][22],
                jpy_per_kwh: saveDataCSV[0][23],
                predict_info:"[ \"$1\",\"$2\",\"$3\",\"$4\",\"$5\",\"$6\",\"$7\",\"$8\",\"$9\",\"$10\",\"$11\",\"$12\" ]"
                            .replace("$1", saveDataCSV[0][24])
                            .replace("$2", saveDataCSV[0][25])
                            .replace("$3", saveDataCSV[0][26])
                            .replace("$4", saveDataCSV[0][27])
                            .replace("$5", saveDataCSV[0][28])
                            .replace("$6", saveDataCSV[0][29])
                            .replace("$7", saveDataCSV[0][30])
                            .replace("$8", saveDataCSV[0][31])
                            .replace("$9", saveDataCSV[0][32])
                            .replace("$10", saveDataCSV[0][33])
                            .replace("$11", saveDataCSV[0][34])
                            .replace("$12", saveDataCSV[0][35]),
                panel_capacity: saveDataCSV[0][36],
            } as any;
            onUpdateSolars(request);
        }else{
            setErrorList(errorList + 
                "登録処理に失敗しました。($1行目、装置ID：$2)\n".replace("$1", indexData.toString()).replace("$2", saveDataCSV[0][1]) +
                "一括登録を中断しました。\n"
            );
            setRunFlag(false)
        }
    };

    const handleUpdateSolars = (data: any) => {
        console.log('>>> handleUpdateSolars');
        if(data.result_code === 0){
            let address_info_array = [
                {
                    code: "AB",
                    slackNotifyFlag: lowerCaseString(saveDataCSV[0][38]) === "true" ? true : undefined,
                    addressList: saveDataCSV[0][37] ? saveDataCSV[0][37].split(";") : [],
                },
                {
                    code: "AC",
                    slackNotifyFlag: lowerCaseString(saveDataCSV[0][40]) === "true" ? true : undefined,
                    addressList: saveDataCSV[0][39] ? saveDataCSV[0][39].split(";") : [],
                },
                {
                    code: "AD",
                    slackNotifyFlag: lowerCaseString(saveDataCSV[0][42]) === "true" ? true : undefined,
                    addressList: saveDataCSV[0][41] ? saveDataCSV[0][41].split(";") : [],
                },
                {
                    code: "GB",
                    slackNotifyFlag: lowerCaseString(saveDataCSV[0][44]) === "true" ? true : undefined,
                    addressList: saveDataCSV[0][43] ? saveDataCSV[0][43].split(";") : [],
                },
                {
                    code: "GC",
                    slackNotifyFlag: lowerCaseString(saveDataCSV[0][46]) === "true" ? true : undefined,
                    addressList: saveDataCSV[0][45] ? saveDataCSV[0][45].split(";") : [],
                },
                {
                    code: "EB",
                    slackNotifyFlag: lowerCaseString(saveDataCSV[0][48]) === "true" ? true : undefined,
                    addressList: saveDataCSV[0][47] ? saveDataCSV[0][47].split(";") : [],
                },
                {
                    code: "EC",
                    slackNotifyFlag: lowerCaseString(saveDataCSV[0][50]) === "true" ? true : undefined,
                    addressList: saveDataCSV[0][49] ? saveDataCSV[0][49].split(";") : [],
                }
            ];

            let address_info_array_request = address_info_array.filter(x => x.addressList?.length || x.slackNotifyFlag);
            let request = {
                mainid: saveDataCSV[0][1],
                address_info_array_count: address_info_array_request.length,
                address_info_array: address_info_array_request
            } as any;
            onUpdateNotifications(request)
        }else{
            setErrorList(errorList + 
                "登録処理に失敗しました。($1行目、装置ID：$2)\n".replace("$1", indexData.toString()).replace("$2", saveDataCSV[0][1]) +
                "一括登録を中断しました。\n"
            );
            setRunFlag(false)
        }
    };

    const handleUpdateNotifications = (data: any) => {
        console.log('>>> handleUpdateNotifications');
        let error = errorList;
        if(data.result_code === 0){
            if(saveDataCSV[0][51]){
                let emailList = saveDataCSV[0][51].split(';');
                if(saveDataCSV[0][51] !== "" && emailList.length > 0){
                    let request = {
                        mainid: saveDataCSV[0][1],
                        addressList: emailList
                        } as any;
                    onUpdateListAddress(request);
                }
            }else{
                error += "($1/$2) 装置ID[$3]が登録されました。\n"
                .replace("$1", (indexData).toString())
                .replace("$2", (dataCSV.length - 1).toString())
                .replace("$3", saveDataCSV[0][1])
                saveDataCSV.shift()
                setUpdateFlag(false)
                if(saveDataCSV.length > 0){
                    if(!openBackDialog){
                        setIndexData(indexData + 1)
                    }
                }else{
                    error += "一括登録が完了しました。(合計：$1件)\n".replace("$1", (dataCSV.length - 1).toString())
                    setApiFlag(false)
                    setRunFlag(false)
                }
                setErrorList(error)
            }
        }else{
            setErrorList(errorList + 
                "登録処理に失敗しました。($1行目、装置ID：$2)\n".replace("$1", indexData.toString()).replace("$2", saveDataCSV[0][1]) +
                "一括登録を中断しました。\n"
            );
            setRunFlag(false)
        }
    };

    const handleUpdateListAddress = (data:any) => {
        let error = errorList;
        if(data.result_code === 0){
            error += "($1/$2) 装置ID[$3]が登録されました。\n"
            .replace("$1", (indexData).toString())
            .replace("$2", (dataCSV.length - 1).toString())
            .replace("$3", saveDataCSV[0][1])
            saveDataCSV.shift()
            setUpdateFlag(false)
            if(saveDataCSV.length > 0){
                if(!openBackDialog){
                    setIndexData(indexData + 1)
                }
            }else{
                error += "一括登録が完了しました。(合計：$1件)\n".replace("$1", (dataCSV.length - 1).toString())
                setApiFlag(false)
                setRunFlag(false)
            }
            setErrorList(error)
        }
        else{
            setErrorList(errorList + 
                "登録処理に失敗しました。($1行目、装置ID：$2)\n".replace("$1", indexData.toString()).replace("$2", saveDataCSV[0][1]) +
                "一括登録を中断しました。\n"
            );
            setRunFlag(false)
        }
    }

    const checkValidation = (element: any, row: number, customerNameList: any) => {
        let error = '';
        // 列数チェック
        if(element.length !== 53 && element.length !== 52 && element.length !== 51){
            return error + message1.replace('$1', row.toString()).replace('$2', element.length.toString());
        }

        if(row > 0){
            // お客様名
            if(element[0] === ""){
                error += message3.replace('$1', row.toString()).replace('$2', `${customerLabelName}名`)
            }else{
                if(!customerNameList.find((r: any) => r === element[0])){
                    error += message5.replace('$1', row.toString()).replace('$2', `${customerLabelName}名`)
                }
            }

            // 装置ID
            if(element[1] === ""){
                error += message3.replace('$1', row.toString()).replace('$2', "装置ID")
            }else{
                if(element[1].length > 6 || !checkAlphabetsAndNumber(element[1])){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "装置ID")
                }
            }

            // 設置場所
            if(element[2] === ""){
                error += message3.replace('$1', row.toString()).replace('$2', "設置場所")
            }

            // IPアドレス
            if(element[3] === ""){
                error += message3.replace('$1', row.toString()).replace('$2', "IPアドレス")
            }else{
                if(!CheckValidateIPv4(element[3])){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "IPアドレス")
                }
            }

            // パルス種別
            if(element[4] === ""){
                error += message3.replace('$1', row.toString()).replace('$2', "パルス種別")
            }else{
                if(element[4] !== "なし" && element[4] !== "デマンド" && element[4] !== "太陽光"){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "パルス種別")
                }else{
                    if(element[4] === "なし"){
                        element[4] = "0"
                    }else if(element[4] === "デマンド"){
                        element[4] = "1"
                    }else{
                        element[4] = "2"
                    }
                }
            }

            // 接点-接点名称1

            // 接点-警報判定値1
            if(element[6] === ""){
                element[6] = "1";
            }else if(element[6] !== "0" && element[6] !== "1"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "接点-警報判定値1")
            }

            // 接点-復帰メッセージ1
            // 接点-警報メッセージ1
            // 接点-接点名称2

            // 接点-警報判定値2
            if(element[10] === ""){
                element[10] = "1";
            }else if(element[10] !== "0" && element[10] !== "1"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "接点-警報判定値2")
            }

            // 接点-復帰メッセージ2
            // 接点-警報メッセージ2
            // 漏電-接点名称1
            // 漏電-接点名称2
            // 漏電-接点名称3
            // 漏電-接点名称4
            // 漏電-接点名称5
            // 漏電-接点名称6

            // デマンド-閾値1
            if(element[19] === ""){
                element[19] = null
            }else{
                if(!Number(element[19])){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "デマンド-閾値1")
                }
            }

            // デマンド-閾値2
            if(element[20] === ""){
                element[20] = null
            }else{
                if(!Number(element[20])){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "デマンド-閾値2")
                }
            }

            // デマンド-閾値3
            if(element[21] === ""){
                element[21] = null
            }else{
                if(!Number(element[21])){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "デマンド-閾値3")
                }
            }

            // 太陽光-名称

            // 太陽光-単価
            if(element[23] === ""){
                element[23] = 0
            }else{
                if(isNaN(Number(element[23])) || !Number.isInteger(Number(element[23])) || Number(element[23]) < 0){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-単価")
                }
            }

            // 太陽光-予想電力-1月
            if(element[24] !== "" && !Number(element[24])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-1月")
            }

            // 太陽光-予想電力-2月
            if(element[25] !== "" && !Number(element[25])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-2月")
            }

            // 太陽光-予想電力-3月
            if(element[26] !== "" && !Number(element[26])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-3月")
            }

            // 太陽光-予想電力-4月
            if(element[27] !== "" && !Number(element[27])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-4月")
            }

            // 太陽光-予想電力-5月
            if(element[28] !== "" && !Number(element[28])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-5月")
            }

            // 太陽光-予想電力-6月
            if(element[29] !== "" && !Number(element[29])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-6月")
            }

            // 太陽光-予想電力-7月
            if(element[30] !== "" && !Number(element[30])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-7月")
            }

            // 太陽光-予想電力-8月
            if(element[31] !== "" && !Number(element[31])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-8月")
            }

            // 太陽光-予想電力-9月
            if(element[32] !== "" && !Number(element[32])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-9月")
            }

            // 太陽光-予想電力-10月
            if(element[33] !== "" && !Number(element[33])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-10月")
            }

            // 太陽光-予想電力-11月
            if(element[34] !== "" && !Number(element[34])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-11月")
            }

            // 太陽光-予想電力-12月
            if(element[35] !== "" && !Number(element[35])){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-予想電力-12月")
            }

            // 太陽光-パネル容量
            if(element[36] === ""){
                element[36] = 0
            }else{
                if(isNaN(Number(element[36])) || !Number.isInteger(Number(element[36])) || Number(element[36]) < 0){
                    error += message2_4_6.replace('$1', row.toString()).replace('$2', "太陽光-パネル容量")
                }
            }

            // 通報-停電-メールアドレス
            if(element[37] !== ""){
                let emailList = element[37].split(";")
                for (let index = 0; index < emailList.length; index++) {
                    if(!checkValidateEmail(emailList[index])){
                        error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-停電-メールアドレス")
                        break
                    }
                }
            }

            // 通報-停電-Slack通知
            if(element[38] === ""){
                element[38] = "false"
            }else if(lowerCaseString(element[38]) !== "true" && lowerCaseString(element[38]) !== "false"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-停電-Slack通知")
            }

            // 通報-復電-メールアドレス
            if(element[39] !== ""){
                let emailList = element[39].split(";")
                for (let index = 0; index < emailList.length; index++) {
                    if(!checkValidateEmail(emailList[index])){
                        error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-復電-メールアドレス")
                        break
                    }
                }
            }

            // 通報-復電-Slack通知
            if(element[40] === ""){
                element[40] = "false"
            }else if(lowerCaseString(element[40]) !== "true" && lowerCaseString(element[40]) !== "false"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-復電-Slack通知")
            }

            // 通報-接点警報-メールアドレス
            if(element[41] !== ""){
                let emailList = element[41].split(";")
                for (let index = 0; index < emailList.length; index++) {
                    if(!checkValidateEmail(emailList[index])){
                        error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-接点警報-メールアドレス")
                        break
                    }
                }
            }

            // 通報-接点警報-Slack通知
            if(element[42] === ""){
                element[42] = "false"
            }else if(lowerCaseString(element[42]) !== "true" && lowerCaseString(element[42]) !== "false"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-接点警報-Slack通知")
            }

            // 通報-デマンド注意警報-メールアドレス
            if(element[43] !== ""){
                let emailList = element[43].split(";")
                for (let index = 0; index < emailList.length; index++) {
                    if(!checkValidateEmail(emailList[index])){
                        error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-デマンド注意警報-メールアドレス")
                        break
                    }
                }
            }

            // 通報-デマンド注意警報-Slack通知
            if(element[44] === ""){
                element[44] = "false"
            }else if(lowerCaseString(element[44]) !== "true" && lowerCaseString(element[44]) !== "false"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-デマンド注意警報-Slack通知")
            }

            // 通報-デマンド限界警報-メールアドレス
            if(element[45] !== ""){
                let emailList = element[45].split(";")
                for (let index = 0; index < emailList.length; index++) {
                    if(!checkValidateEmail(emailList[index])){
                        error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-デマンド限界警報-メールアドレス")
                        break
                    }
                }
            }

            // 通報-デマンド限界警報-Slack通知
            if(element[46] === ""){
                element[46] = "false"
            }else if(lowerCaseString(element[46]) !== "true" && lowerCaseString(element[46]) !== "false"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-デマンド限界警報-Slack通知")
            }

            // 通報-軽漏電警報-メールアドレス
            if(element[47] !== ""){
                let emailList = element[47].split(";")
                for (let index = 0; index < emailList.length; index++) {
                    if(!checkValidateEmail(emailList[index])){
                        error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-軽漏電警報-メールアドレス")
                        break
                    }
                }
            }

            // 通報-軽漏電警報-Slack通知
            if(element[48] === ""){
                element[48] = "false"
            }else if(lowerCaseString(element[48]) !== "true" && lowerCaseString(element[48]) !== "false"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-軽漏電警報-Slack通知")
            }

            // 通報-重漏電警報-メールアドレス
            if(element[49] !== ""){
                let emailList = element[49].split(";")
                for (let index = 0; index < emailList.length; index++) {
                    if(!checkValidateEmail(emailList[index])){
                        error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-重漏電警報-メールアドレス")
                        break
                    }
                }
            }

            // 通報-重漏電警報-Slack通知
            if(element[50] === ""){
                element[50] = "false"
            }else if(lowerCaseString(element[50]) !== "true" && lowerCaseString(element[50]) !== "false"){
                error += message2_4_6.replace('$1', row.toString()).replace('$2', "通報-重漏電警報-Slack通知")
            }

            // 帳票配信-配信先メールアドレス
            if(element[51]){
                if(element[51] !== ""){
                    let emailList = element[51].split(';');
                    for (let index = 0; index < emailList.length; index++) {
                        if(!checkValidateEmail(emailList[index])){
                            error += message2_4_6.replace('$1', row.toString()).replace('$2', "帳票配信-配信先メールアドレス");
                            break
                        }
                    }
                }
            }
            
            // 管理組織名に対して個別チェック
            if(element[52]){
                if(element[52] !== ""){
                    if(!customerNameList.find((r: any) => r === element[52])){
                        error += message5.replace('$1', row.toString()).replace('$2', `管理組織名`);
                    }
                }
            }
        }
        return error
    }

    const checkAlphabetsAndNumber = (value: string) => {
        return /^[A-Za-z0-9]*$/.test(value);
    }

    const checkValidateEmail = (email: string) => {
        return /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/i.test(email);
    };

    const CheckValidateIPv4 = (iPv4: string) => {
        return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(iPv4)
    };

    const handleDialogOK = () => {
        setOpenBackDialog(false);
        if(!updateFlag){
            setErrorList(errorList + "一括登録を中断しました。") 
            setRunFlag(false);    
        }else{
            setClickCancelFlag(true);
        }
        // setErrorList(errorList + "一括登録を中断しました。")
    }

    const handleDialogCancel = () => {
        setOpenBackDialog(false);
        if(validateFlag){
            setIndexCSV(indexCSV + 1);
        }

        if(apiFlag && !updateFlag){
            setIndexData(indexData + 1);
        }
    };

    
    const handleRemoveFile = () => {
        setSaveDataCSV([])
        setDataCSV([])
        setErrorList('')
        setCustomerDB([])
        setSelectCSV(false)
        setFileName('')
        setFilePath('')

        setValidateFlag(false)
        setApiFlag(false)
        setIndexCSV(0)
        setIndexData(0)
        setCheckError(false)
        setUpdateFlag(false)
        setClickCancelFlag(false)
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <Stack direction='row' justifyContent="flex-start" spacing={1.5} alignItems='center'>
                    <Typography variant="h5">一括登録ファイルのアップロード</Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <CSVDownloader
                            type={Type.Link}
                            filename={'監視装置-監視装置　一括登録'}
                            bom={true}
                            data={headerCSV}
                            >
                            <Button variant="contained" disabled = {runFlag}>テンプレートを取得</Button>
                        </CSVDownloader>
                    </Stack>
                    
                    <CSVReader
                        config ={{
                            skipEmptyLines: true,
                        }}
                        
                        onUploadAccepted={(results: any, file: any) => {
                            let data = [] as any[]
                            for (let index = 0; index < results.data.length; index++) {
                                let isEmptyRow = results.data[index].find((item: any) => item.trim().length > 0);
                                if(isEmptyRow){
                                    data.push(results.data[index])
                                }
                            }
                            setDataCSV(data)
                            setFileName("ファイル[" + file.name + "]を取り込みました。\n")
                            setFilePath(file.name)
                            console.log(file);
                            setErrorList("ファイル[" + file.name + "]を取り込みました。\n")
                            setSelectCSV(true)
                        }}
                    >
                        {({
                            getRootProps,
                            acceptedFile,
                        }: any) => (
                            <>
                                <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={8} md={9} style={{display: 'flex', alignItems: 'center'}}>
                                            <FormControl sx={{width:"100%" }} size="small" variant="outlined">
                                                <InputLabel htmlFor="filePath">ファイル</InputLabel>
                                                <OutlinedInput
                                                    {...getRootProps()}
                                                    id="filePath"
                                                    type={'text'}
                                                    readOnly={true}
                                                    sx={{ backgroundColor: 'floralwhite' }}
                                                    value={filePath}
                                                    endAdornment={
                                                    <>{selectCSV && <InputAdornment position="end">
                                                        <IconButton onClick={()=>{console.log(acceptedFile); handleRemoveFile();}} disabled = {runFlag}>
                                                            <Close sx={{ fontSize: "25px", color: 'red', cursor: 'pointer' }} />
                                                        </IconButton>
                                                    </InputAdornment>}
                                                    </>
                                                    }
                                                    label="ファイル"
                                                    onClick={() => {}}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button variant="contained" {...getRootProps()} disabled = {runFlag}>ファイルを追加</Button>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </>
                        )}
                    </CSVReader>
                    <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                        <Grid container spacing={1} style={{overflowY:"auto"}}>
                            <Grid item width={'100%'}>
                                <TextField
                                    fullWidth
                                    name="result"
                                    label="登録状況表示"
                                    multiline
                                    rows={26}
                                    value={errorList}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" disabled = { !selectCSV } loading={runFlag}>実行</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel} disabled = {clickCancelFlag}>キャンセル</Button>
                        
                    </Stack>
                </form>
                {openBackDialog ?
                    <AlertDialog title={"一括登録中断"} message={"一括登録を中断しますか？"} isOpen={true} onOK={handleDialogOK} onCancel={handleDialogCancel} /> :
                    <div />
                }
            </div>
            <FetchHelper fetchId={getAllCustomers} onComplete={(success, data, error) =>
                success ? handleSuccessGetAllCustomers(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={createGateways} onComplete={(success, data, error) =>
                success ? handleCreateGateways(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={updatePoint} onComplete={(success, data, error) =>
                success ? handleUpdatePoints(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={updateLeaks} onComplete={(success, data, error) =>
                success ? handleUpdateLeaks(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={updateDemand} onComplete={(success, data, error) =>
                success ? handleUpdateDemands(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={updateSolars} onComplete={(success, data, error) =>
                success ? handleUpdateSolars(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={updateNotifications} onComplete={(success, data, error) =>
                success ? handleUpdateNotifications(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={UPDATE_LIST_ADDRESS} onComplete={(success, data, error) =>
                success ? handleUpdateListAddress(data) : handleError(success, data, error)} />
        </>
    );
};


const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = {
    onGetAllCustomer: (params: { [key: string]: any }) => actionCreators.fetch(getAllCustomers, `/master/customers/get`, "GET", params),
    onCreateGateways: (data: any) => actionCreators.fetch(createGateways, `/master/gateways/add`, "POST", data, false, true),
    onUpdatePoints: (data: any) => actionCreators.fetch(updatePoint, `/master/points/edit`, 'POST', data, false, true),
    onUpdateLeaks: (data: any) => actionCreators.fetch(updateLeaks, `/master/leaks/edit`, 'POST', data, false, true),
    onUpdateDemands: (data: any) => actionCreators.fetch(updateDemand, `/master/demands/edit`, 'POST', data, false, true),
    onUpdateSolars: (data: any) => actionCreators.fetch(updateSolars, `/master/solars/edit`, 'POST', data, false, true),
    onUpdateNotifications: (data: any) => actionCreators.fetch("UPDATE_NOTIFICATIONS", `/master/notifications/edit`, 'POST', data, false, true),
    onUpdateListAddress: (params:any) => actionCreators.fetch(UPDATE_LIST_ADDRESS,`/master/reports-demand/edit`,"POST",params,false,true),
    onCreate: (data: any) => actionCreators.fetch("CREATE_DEVICE", `/master/gateways/add`, "POST", data, false, true),
    onCreateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "監視装置　一括登録",
            body: "装置を登録しました。",
        }),
    onCreateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "監視装置　一括登録",
            body: "装置の登録が出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDeviceList as any);


