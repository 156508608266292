import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { MonitoringOperatorCustomerRoleValue } from "../../../statics";

// 検索フォーム
export interface ItemForm {
    group_id:any;
    base_number:any;
    base_name:any;
}

export type BaseAddFormProps = {
    authRole:any;
    customerGroupOption:any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const BaseAdd = (props: BaseAddFormProps) => {
    const { authRole, customerGroupOption, onOK, onCancel, } = props;

    // 統括管理者
    const generalAdminstratorRole = useMemo(() => {
        return authRole?.role_list.find((item:any) => item.name == "統括管理者").role;
    },[authRole]);

    // 統括管理者以下( 6 >= target がTRUE)
    const isShow = useMemo(() => {
        return (6 >= authRole?.role || authRole?.role ==  generalAdminstratorRole)
    },[authRole,generalAdminstratorRole])

    // 監視事業者
    const customerMonitoringCompanyRole = useMemo(() => {
        return authRole?.role_list.find((item:any) => item.role == MonitoringOperatorCustomerRoleValue).role;
    },[authRole]);

    const isCustomerMonitoringCompanyRole = useMemo(() => {
            return authRole?.role ==  customerMonitoringCompanyRole;
        },[authRole]);
        
    useEffect(() => {
        if(isCustomerMonitoringCompanyRole){
            setValue("group_id",authRole.company_group_id);
        }
    },[isCustomerMonitoringCompanyRole]);

    // 初期値設定
    const initialValue: ItemForm = {
        group_id:"",
        base_number:"",
        base_name:"",
    }

    const { handleSubmit, formState: { errors }, control, setValue, clearErrors, getValues } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: ItemForm) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ItemForm) => {
        const params = {
            group_id:formValue.group_id,
            base_number:formValue.base_number,
            base_name:formValue.base_name,
        }
        return params;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <div style={{ height: 'auto', width: '100%'}}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    {/* 基本情報 */}
                    <Stack spacing={2.5} style={{ marginLeft: '10px', width: '98%', position:"relative" }}>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                            <Grid sx={{pt:1.5}} container spacing={1} direction="row">
                                <FormControl size="small" fullWidth  error={!!errors?.group_id}>
                                    <InputLabel>組織グループ</InputLabel>
                                    <Controller
                                        control={control}
                                        name="group_id"
                                        rules={{ required: '必須項目です。選択してください。' }}
                                        render={({ field }) => (
                                            <>
                                                <Select
                                                    {...field}
                                                    error={!!errors?.group_id}
                                                    label={"組織グループ"}
                                                    onChange={(event) => {                                                        
                                                        field.onChange(event);
                                                    }}
                                                    readOnly={isCustomerMonitoringCompanyRole || !isShow}
                                                    style={{backgroundColor: (isCustomerMonitoringCompanyRole || !isShow) ? "floralwhite" : "white"}}
                                                >
                                                    {customerGroupOption.map((item:any) => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    !!errors?.group_id &&
                                                    <FormHelperText style={{ color: 'red' }}>
                                                        {errors?.group_id?.message}
                                                    </FormHelperText>
                                                }
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="base_number"
                                    rules={{ required: '必須項目です。入力してください。',
                                        pattern:{
                                            value: /^[1-9][0-9]*$/,
                                            message:"整数を入力してください。"
                                        }
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label={`拠点No.`}
                                                size="small"
                                                error={!!errors?.base_number}
                                                helperText={errors?.base_number?.message}
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="base_name"
                                    rules={{ required: '必須項目です。入力してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label={`拠点名`}
                                                size="small"
                                                error={!!errors?.base_name}
                                                helperText={errors?.base_name?.message}
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ width:"98%" ,mt: 2,marginLeft:"10px"}}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};

export default BaseAdd;