import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Customer, OptionItem, PageData } from '../../../models';
import { customerLabelName } from '../../../statics';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

export const PageSizeList = [
    { value: '10', label: '10', attribute: '', },
    { value: '50', label: '50', attribute: '', },
    { value: '100', label: '100', attribute: '', },
    { value: '500', label: '500', attribute: '', },
    { value: '1000', label: '1000', attribute: '', },
] as OptionItem[];

const array = [
    {
        value:"AB",
        label:"停電"
    },
    {
        value:"AC",
        label:"復電"
    },
    {
        value:"AD",
        label:"接点警報"
    },
    {
        value:"GB",
        label:"デマンド注意警報"
    },
    {
        value:"GC",
        label:"デマンド限界警報"
    },
    {
        value:"EB",
        label:"軽漏電警報"
    },
    {
        value:"EC",
        label:"重漏電警報"
    },
    {
        value:"BA",
        label:"微地絡下限検出",
    },
    {
        value:"BB",
        label:"微地絡警戒検出"
    },
    {
        value:"BC",
        label:"微地絡警報"
    },
    {
        value:"BD",
        label:"地絡警報"
    },
    {
        value: "BE",
        label: "微地絡トレンド電流"
    },
]

// -------------
// Props
export type NotifyGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    keyword:string;
    onChangeSearchCondition: (name: string, value: any) => void;
    onShowDetails: (mainid: string) => void;
    openSearch: boolean;
    authRole:any;
};

// -------------
// Component
export const NotifyGrid = forwardRef((props: NotifyGridProps, ref) => {
    const { colState, pageSize, pageData, keyword, onChangeSearchCondition, onShowDetails, openSearch, authRole } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const cellTypeRender = (props:any) => {
        let result = "";
        const { address_info_array } = props.data;
        const codeRestrictionItem:any = [];
        address_info_array.forEach((item:any,index:any) => {
            const codeRestriction = array.find((code:any) => code.value == item?.code);
            if(codeRestriction){
                let data = codeRestriction.label;
                if(item.addressList.length == 0){
                    data += "\n";
                }else{
                    for(let  i = 0;i < item.addressList.length;i++){
                        data += "\n";
                    }
                }
                codeRestrictionItem.push(data);
            }
        });
        codeRestrictionItem.forEach((item:any) => {
            result = result + item;
        });
        return result;
    }

    const cellAddressRender = (props:any) => {
        const { address_info_array } = props.data;
        let result = "";
        address_info_array.forEach((item:any) => {
            let data = "";
            if(item.addressList.length == 0){
                data += "\n";
            }else{
                for(const address of item.addressList){
                    data = data + address + "\n";
                }
            }
            result += data;
        })
        return result;
    }


    const cellSlackRenderer = (props:any) => {
        const { address_info_array } = props.data;
        let result = "";
        address_info_array.forEach((item:any) => {
            let data = "";
            if(item.slackNotifyFlag){
                data = "ON";
            }else{
                data = "OFF";
            }
            if(item.addressList.length == 0){
                data += "\n";
            }else{
                for(let  i = 0;i < item.addressList.length;i++){
                    data += "\n";
                }
            }
            
            result += data;
        });
        return result;
    }
    
    const CellDetailRenderer = (props:any) => {
        const { mainid } = props.data;

        let isDisable = true;

        // 通報編集
        if (authRole !== undefined)
            isDisable = checkRoleClickBtn('GW-notifyview', authRole, props.data);
        
        return <>
            <IconButton disabled={isDisable} onClick={() => {
                onShowDetails(mainid);
            }}>
                { isDisable ?
                    <EditIcon fontSize="small" sx={{ color: 'text.disabled', opacity: '0.5' }}/>
                    : <EditIcon fontSize="small"/>
                }
            </IconButton>
        </>
    }

    const setAddress = (props:any) => {
        const { address_info_array } = props.data;
        let result = "";
        address_info_array.forEach((item:any) => {
            let data = "";
            for(const address of item.addressList){
                data = data + address + "\n";
            }
            result += data;
        })
        return result;
    }

    const setType = (props:any) => {
        let result = "";
        const { address_info_array } = props.data;
        const codeRestrictionItem:any = [];
        address_info_array.forEach((item:any) => {
            const codeRestriction = array.find((code:any) => code.value == item?.code);
            if(codeRestriction){
                let data = codeRestriction.label;
                for(let  i = 0;i < item.addressList.length;i++){
                    data += "\n";
                }
                codeRestrictionItem.push(data);
            }
        });
        codeRestrictionItem.forEach((item:any) => {
            result = result + item;
        });
        return result;
    }

    const setSlack = (props:any) => {
        const { address_info_array } = props.data;
        let result = "";
        address_info_array.forEach((item:any) => {
            let data = "";
            if(item.slackNotifyFlag){
                data = "ON";
            }else{
                data = "OFF";
            }
            for(let  i = 0;i < item.addressList.length;i++){
                data += "\n";
            }
            result += data;
        });
        return result;
    }
    // 列の個別定義
    const columnDefs = [
        {
            headerName: ``,
            field: "detail",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            sortable:false,
            // resizable:false,
            width: colState ? colState[0].width : 80,
            cellRenderer:CellDetailRenderer,
        },
        {
            headerName: `グループ名`,
            field: "group_name",
            rowDrag: false,
            width: colState ? colState[1].width : 145,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: colState ? colState[2].width : 180,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            width: colState ? colState[3].width : 200,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
        },
        {
            headerName: "管理組織名",
            field: "manage_customer_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            width: colState ? colState[4].width : 150,
        },
        {
            headerName: "装置ID",
            field: "mainid",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            width: colState ? colState[5].width : 90,
        },
        {
            headerName: "IPアドレス",
            field: "ip_address",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            width: colState ? colState[6].width : 140,
        },
        {
            headerName: "パルス種別",
            field: "pulse_type_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            width: colState ? colState[7].width : 105,
        },
        {
            headerName: "種別",
            field: "address_info_array",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            width: colState ? colState[8].width : 170,
            cellRenderer: cellTypeRender,
            valueGetter:setType,
            wrapText:true,
            autoHeight:true,
            sortable:false,
        },
        {
            headerName: "Slack通知",
            field: "address_info_array",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            width: colState ? colState[9].width : 100,
            cellRenderer: cellSlackRenderer,
            valueGetter:setSlack,
            wrapText:true,
            autoHeight:true,
            sortable:false,
        },
        {
            headerName: "通報アドレス",
            field: "address_info_array",
            resizable: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            width: colState ? colState[10].width : 220,
            cellRenderer: cellAddressRender,
            valueGetter: setAddress,
            wrapText:true,
            autoHeight:true,
            sortable:false,
        },
    ];

    const checkRoleClickBtn = (roleName: string, authRole: any, data: any) => {
        let isDisable = true;
        if (authRole.auth_role_map){
            if (authRole.auth_role_map[roleName + '-all']){
                isDisable = false;
            } else {
                if(authRole.auth_role_map[roleName + '-group']){
                    if(authRole.company_group_id == data.group_id){
                        isDisable = false;
                    }
                }
                if (authRole.auth_role_map[roleName + '-belong']){
                    if (authRole.company_id == data.customer_id)
                        isDisable = false;
                }
                if (authRole.auth_role_map[roleName + '-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == data.customer_id).length > 0)
                        isDisable = false;
                }
            }
        }
        return isDisable;
    }

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    if(authRole && authRole.base_view_flag == 1){
        columnDefs.splice(2, 0, 
            {
                headerName: "拠点",
                field: "base_name",
                rowDrag: false,
                cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
                width: 100,
            },
        );
    }

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
            };
        }
        return {
            backgroundColor: 'white',
        };
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 275px)' : 'calc(100vh - 230px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        getRowStyle={getRowStyle}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageSizeCustom={PageSizeList} pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default NotifyGrid;
